import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Card, ListGroup, Table, Row, Col } from "react-bootstrap";
import { useCurrentPolicyContext } from "../../actions/CurrentPolicyContext";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { getSingleData } from "../../Utils/CallApiUtils";
import { convertDateFormatWithOutTime } from "../../Utils/commonFunction";
import UserSetting from "../../UserSettings.json";
import downloadPdfIcon from "../../assets/icons8-download-pdf-48.png";

export default function Claim() {
  const { currentSelectPolicyID, setCurrentSelectPolicyID } =
    useCurrentPolicyContext();
  const [claimData, setClaimData] = useState([]);
  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (currentSelectPolicyID > 0) {
      getClaimDetail();
    } else {
      window.location = "/";
    }
  }, [currentSelectPolicyID]);

  const getClaimDetail = async () => {
    await getSingleData(
      `InsuranceClaim/AllClaimDetailModel?PolicyID=${currentSelectPolicyID}`,
      setClaimData,
      setIsLoading
    );
  };

  return (
    <>
      <div className="">
        {claimData.length > 0 ? (
          <div className="scrollable-card-body">
            {claimData.map((item) => (
              <Card
                style={{ border: "2px solid #6366f1"}}
                className="projcard"
              >
                <Card.Header
                  style={{
                    backgroundColor: "#20336b",
                    color: "white",
                    fontSize: "18px",
                    padding: "10px",
                  }}
                >
                  <Row  >
                    <Col sm={12} md={6} lg={6}>
                      <span>ClaimNo:-{item.companyClaimNumber}</span>
                    </Col>
                    <Col
                      sm={12}
                      md={6}
                      lg={6}
                      className="lg:text-end md:text-end"
                    >
                      <span>Patient :-{item.patientName}</span>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row className="d-flex justify-content-between lg:p-2 md:p-2">
                    <Col
                      sm={12}
                      md={4}
                      lg={4}
                      style={{ padding: window.innerWidth < 768 && "5px" }}
                    >
                      <span>
                        <strong>Hospital:&nbsp;</strong>
                        {item.hospitalName}
                      </span>
                    </Col>
                    {window.innerWidth < 768 && <hr />}
                    <Col
                      className="lg:text-center"
                      sm={12}
                      md={4}
                      lg={4}
                      style={{ padding: window.innerWidth < 768 && "5px" }}
                    >
                      <span>
                        <strong>Hospital Address:&nbsp;</strong>
                        {item.hospitalAddress}
                      </span>
                    </Col>
                    {window.innerWidth < 768 && <hr />}
                    <Col
                      className="lg:text-center"
                      sm={12}
                      md={4}
                      lg={4}
                      style={{ padding: window.innerWidth < 768 && "5px" }}
                    >
                      <span>
                        <strong>Intimation Status:&nbsp;</strong>
                        {item.intimationStatusName}
                      </span>
                    </Col>
                  
                    
                  </Row>
                  <Row>
                  {window.innerWidth < 768 && <hr />}
                    <Col
                      className="lg:text-start md:text-start"
                      sm={12}
                      md={6}
                      lg={6}
                      style={{ padding: window.innerWidth < 768 && "5px" }}
                    >
                      <span> 
                        <strong>Ailment:&nbsp;</strong> {item.ailment}
                      </span>
                    </Col>
                    <Col
                      className="lg:text-end md:text-end"
                      sm={12}
                      md={6}
                      lg={6}
                      style={{ padding: window.innerWidth < 768 && "5px" }}
                    >
                      <span>
                        <strong>Date Of Admission :&nbsp;</strong> {item.dateOfAdmission && convertDateFormatWithOutTime(item.dateOfAdmission)} 
                      </span>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="d-flex justify-content-between lg:p-2 md:p-2 ">
                    <Col
                      className="lg:text-start md:text-end"
                      sm={12}
                      md={4}
                      lg={4}
                      style={{
                        padding: window.innerWidth < 768 && "5px",
                      }}
                    >
                      <span>
                        <strong>Claim Amount:&nbsp;</strong> {item.claimAmount}
                      </span>
                    </Col>
                    {window.innerWidth < 768 && <hr />}
                    <Col
                      sm={12}
                      md={4}
                      lg={4}
                      className="lg:text-center md:text-center "
                      style={{
                        padding: window.innerWidth < 768 && "5px",
                      }}
                    >
                      <span>
                        <strong> Deduct   Amount:&nbsp;</strong> {item.decutAmount}
                      </span>
                    </Col>
                    {window.innerWidth < 768 && <hr />}
                    <Col
                      className="lg:text-end md:text-end "
                      sm={12}
                      md={4}
                      lg={4}
                      style={{
                        padding: window.innerWidth < 768 && "5px",
                      }}
                    >
                      <span>
                        <strong>Payable Amount:&nbsp;</strong>
                        {item.payableAmount}
                      </span>
                    </Col>
                    {window.innerWidth < 768 && <hr />}
                  </Row>
                  <Row className="d-flex justify-content-between lg:p-2 md:p-2 ">
                    <Col
                      className="lg:text-start md:text-end"
                      sm={12}
                      md={4}
                      lg={4}
                      style={{
                        padding: window.innerWidth < 768 && "5px",
                      }}
                    >
                      <span>
                        <strong>File Date:&nbsp;</strong>{" "}
                        {item.tpaFileDate && convertDateFormatWithOutTime(item.tpaFileDate)}
                      </span>
                    </Col>
                    {window.innerWidth < 768 && <hr />}
                    <Col
                      sm={12}
                      md={4}
                      lg={4}
                      className="lg:text-center md:text-center "
                      style={{
                        padding: window.innerWidth < 768 && "5px",
                      }}
                    >
                      <span>
                        <strong> UTR:&nbsp;</strong> {item.utr}
                      </span>
                    </Col>
                    <Col
                      className="lg:text-end md:text-end "
                      sm={12}
                      md={4}
                      lg={4}
                      style={{
                        padding: window.innerWidth < 768 && "5px",
                      }}
                    >
                      <span>
                        <strong>Payment Date:&nbsp;</strong>
                        {item.date && convertDateFormatWithOutTime(item.date)}
                      </span>
                    </Col>
                    {window.innerWidth < 768 && <hr />}
                  </Row>
                 {item.imagePath && <Row>
                    <NavLink
                      to={UserSetting.imgURL + item.imagePath}
                      target="_blank"
                      className="text-end flex justify-end "
                    >
                      {" "}
                      <img src={downloadPdfIcon} alt="" className="w-8 h-8" />
                    </NavLink>
                  </Row>}
                </Card.Body>
                <Card.Footer
                  className="m-0 p-0"
                  style={{ borderTop: "2px solid #20336b" }}
                >
                  {item.subClaim &&
                    item.subClaim.length > 0 &&
                    item.subClaim.map((Subitem, index) => (
                      <Card className="SubClaimCard">
                        <Card.Header className="d-flex justify-content-between">
                          <span>Additional Claim {index + 1}</span>
                          <span>Intimation Status: {Subitem.intimationStatusName}</span>
                         {Subitem.imagePath && <NavLink
                            to={`${UserSetting.imgURL}${Subitem.imagePath}`}
                            target="_blank"
                          >
                            <img
                              src={downloadPdfIcon}
                              alt="Download PDF"
                              className="w-8 h-8"
                            />
                          </NavLink>}
                        </Card.Header>

                        <Card.Body className="p-0">
                          <Row className="d-flex justify-content-between lg:p-2 md:p-2 ">
                            <Col
                              className="lg:text-start md:text-end"
                              sm={12}
                              md={4}
                              lg={4}
                              style={{
                                padding: window.innerWidth < 768 && "5px",
                              }}
                            >
                              <span>
                                <strong>Claim Amount:&nbsp;</strong>{" "}
                                {Subitem.claimAmount}
                              </span>
                            </Col>
                            {window.innerWidth < 768 && <hr />}
                            <Col
                              sm={12}
                              md={4}
                              lg={4}
                              className="lg:text-center md:text-center "
                              style={{
                                padding: window.innerWidth < 768 && "5px",
                              }}
                            >
                              <span>
                                <strong> Deduct  Amount:&nbsp;</strong>{" "}
                                {Subitem.decutAmount}
                              </span>
                            </Col>
                            {window.innerWidth < 768 && <hr />}
                            <Col
                              className="lg:text-end md:text-end "
                              sm={12}
                              md={4}
                              lg={4}
                              style={{
                                padding: window.innerWidth < 768 && "5px",
                              }}
                            >
                              <span>
                                <strong>Payable Amount:&nbsp;</strong>
                                {Subitem.payableAmount}
                              </span>
                            </Col>
                            {window.innerWidth < 768 && <hr />}
                          </Row>
                          <Row className="d-flex justify-content-between lg:p-2 md:p-2 ">
                            <Col
                              className="lg:text-start md:text-end"
                              sm={12}
                              md={4}
                              lg={4}
                              style={{
                                padding: window.innerWidth < 768 && "5px",
                              }}
                            >
                              <span>
                                <strong>File Date:&nbsp;</strong>{" "}
                                {Subitem.tpaFileDate && convertDateFormatWithOutTime(
                                  Subitem.tpaFileDate
                                )}
                              </span>
                            </Col>
                            {window.innerWidth < 768 && <hr />}
                            <Col
                              sm={12}
                              md={4}
                              lg={4}
                              className="lg:text-center md:text-center "
                              style={{
                                padding: window.innerWidth < 768 && "5px",
                              }}
                            >
                              <span>
                                <strong> UTR:&nbsp;</strong> {Subitem.utr}
                              </span>
                            </Col>
                            <Col
                              className="lg:text-end md:text-end "
                              sm={12}
                              md={4}
                              lg={4}
                              style={{
                                padding: window.innerWidth < 768 && "5px",
                              }}
                            >
                              <span>
                                <strong>Payment Date:&nbsp;</strong>
                                {Subitem.date && convertDateFormatWithOutTime(Subitem.date)}
                              </span>
                            </Col>
                            {window.innerWidth < 768 && <hr />}
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}
                </Card.Footer>
              </Card>
            ))}
          </div>
        ) : (
          <p>Not Any Claim In This Policy</p>
        )}
      </div>
    </>
  );
}
