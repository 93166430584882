import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Table,
  Form,
  InputGroup,
  Dropdown,
  Button,
  Modal,
} from "react-bootstrap";
import { getSingleData } from "../../Utils/CallApiUtils";
import { useLoadingContext } from "../../Loading/LoadingContext";
import { convertDateFormatWithOutTime } from "../../Utils/commonFunction";
import UserSetting from "../../UserSettings.json";
import api from "../../Utils/api";
import { NavLink } from "react-router-dom";
import { useCurrentPolicyContext } from "../../actions/CurrentPolicyContext";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { decryptAndGetItemLocalStorage } from "../../Utils/cryptosessionStorage";
import InputGroupWithIcon from "../../Common/InputGroupWithIcon ";
import Swal from "sweetalert2";
import Endorsement from "../Endorsement/Endorsement";
import Claim from "../Claim/Claim";
import Courier from "../Courier/Courier";

const emtSearchPolicy = {
  policyNo: "",
  netAmount: "",
  companyName: "",
  externalRemarks: "",
  policyHolderName: "",
};

const emtCount = {
  TotalPolicy: 0,
  TotalActivePolicy: 0,
  TotalExpiredPolicy: 0,
};

const Dashboard = () => {
  const loginUserMobileNo = decryptAndGetItemLocalStorage("_MNO_C");
  const [policyData, setPolicyData] = useState([]);
  const [storeAllPolicyData, setStoreAllPolicyData] = useState([]);
  const { setIsLoading } = useLoadingContext();
  const { currentSelectPolicyID, setCurrentSelectPolicyID } =
    useCurrentPolicyContext();
  const [selectedValue, setSelectedValue] = useState("Active");
  const [options, setOptions] = useState([]);
  const policyTypeHadeRef = useRef();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchPolicyDetails, setSearchPolicyDetails] =
    useState(emtSearchPolicy);
  const [CountsPolicyAsType, setCountsPolicyAsType] = useState(emtCount);

  const [showEndorsementModel, setShowEndorsementModel] = useState(false);
  const [showClaimModel, setShowClaimModel] = useState(false);
  const [showCourierModel, setShowCourierModel] = useState(false);

  useEffect(() => {
    getPolicyDetail();
    setShowDropdown(false);
  }, [loginUserMobileNo]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getPolicyDetail = async () => {
    const Result = await getSingleData(
      `Policy/GetPolicyAllDataByClientMobileNo?clientMobileNo=${loginUserMobileNo}`,
      setStoreAllPolicyData,
      setIsLoading
    );
    debugger;
    if (Result) {
      const All = Result;
      const Actives = Result.filter((item) => !item.isPolicyExpired);
      const Expired = Result.filter((item) => item.isPolicyExpired);
      setPolicyData(Actives);
      setCountsPolicyAsType({
        TotalPolicy: All.length,
        TotalActivePolicy: Actives.length,
        TotalExpiredPolicy: Expired.length,
      });
    }
  };

  const CountViewPolicy = (id) => {
    api.get(`Policy/CountViewPolicyByClient?PolicyID=${id}`);
  };

  const handleChange = async (e) => {
    const value = e.target ? e.target.value : e;
    setSelectedValue(value);
  };

  const refreshSearchItem = () => {
    setSearchPolicyDetails(emtSearchPolicy);
    getPolicyDetail();
  };

  const handleSearchPolicyByActiveAndEx = (e) => {
    let filteredData = storeAllPolicyData;
    const value = e.target ? e.target.value : e;
    setSelectedValue(value);
  
    const now = new Date();
    const thirtyDaysAgo = new Date(now);
    thirtyDaysAgo.setDate(now.getDate() - 30);
    const thirtyDaysAfter = new Date(now);
    thirtyDaysAfter.setDate(now.getDate() + 30);
  
    switch (value) {
      case "Active":
        filteredData = filteredData.filter((item) => !item.isPolicyExpired);
        break;
  
      case "Expired":
        filteredData = filteredData.filter((item) => item.isPolicyExpired);
        break;
  
      case "30DayExpired":
        filteredData = filteredData.filter((item) => 
          new Date(item.policyEndDate) >= thirtyDaysAgo &&
          new Date(item.policyEndDate) <= thirtyDaysAfter &&
          !item.renewalFlag
        );
        break;
  
      default:
        filteredData = filteredData;
        break;
    }
  
    setPolicyData(filteredData);
    setShowDropdown(false);
  };
  

  const handleSearchPolicy = () => {
    var filteredData = storeAllPolicyData;
    // const value = selectedValue;

    // switch (value) {
    //   case "Active":
    //     filteredData = filteredData.filter((item) => !item.isPolicyExpired);
    //     break;

    //   case "Expired":
    //     filteredData = filteredData.filter((item) => item.isPolicyExpired);
    //     break;

    //   default:
    //     filteredData = filteredData;
    //     break;
    // }

    if (searchPolicyDetails.policyNo !== "") {
      filteredData = filteredData.filter((item) =>
        item.policyNo
          .toUpperCase()
          .includes(searchPolicyDetails.policyNo.toUpperCase())
      );
    }

    if (searchPolicyDetails.netAmount !== "") {
      filteredData = filteredData.filter(
        (item) => item.netAmount == searchPolicyDetails.netAmount
      );
    }

    if (searchPolicyDetails.companyName !== "") {
      filteredData = filteredData.filter((item) =>
        item.insuranceCompanyName
          ?.toUpperCase()
          .includes(searchPolicyDetails.companyName.toUpperCase())
      );
    }

    if (searchPolicyDetails.externalRemarks !== "") {
      filteredData = filteredData.filter((item) =>
        item.externalRemarks
          ?.toUpperCase()
          .includes(searchPolicyDetails.externalRemarks.toUpperCase())
      );
    }

    if (searchPolicyDetails.policyHolderName !== "") {
      filteredData = filteredData.filter((item) =>
        item.policyHolderName
          ?.toUpperCase()
          .includes(searchPolicyDetails.policyHolderName.toUpperCase())
      );
    }
    setPolicyData(filteredData);

    setShowDropdown(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchPolicyDetails({ ...searchPolicyDetails, [name]: value });
  };

  const resetFilter = () => {
    setSearchPolicyDetails(emtSearchPolicy);
    setSelectedValue("Active");
    getPolicyDetail();
  };

  const SendSMSAndEmail = (id) => {
    debugger;
    setIsLoading(true);
    api
      .get(`Policy/SendEmailAndWhatsAppPolicyByClientSide?PolicyID=${id}`)
      .then((Result) => {
        Swal.fire({
          icon: "success",
          title: Result.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <>
        <Card className="p-0 m-0">
          <Card.Header className="MainCardHeaderCss my-1">
            {!isMobile ? (
              <Row className="mx-0">
                <Col md={4} sm={4} lg={1} className="d-flex  items-center">
                  <Form.Select
                    aria-label="Default select example"
                    value={selectedValue}
                    onChange={handleSearchPolicyByActiveAndEx }
                  >
                    <option value="All">
                      All {CountsPolicyAsType.TotalPolicy}
                    </option>
                    <option value="Active">
                      Active {CountsPolicyAsType.TotalActivePolicy}
                    </option>
                    <option value="Expired">
                      Expired {CountsPolicyAsType.TotalExpiredPolicy}
                    </option>
                    <option value="30DayExpired">
                      30DayExpired 
                      </option>
                  </Form.Select>
                </Col>
                <InputGroupWithIcon
                  Label="PolicyNo"
                  ColSize={{ md: 4, sm: 4, lg: 2 }}
                  type="text"
                  name="policyNo"
                  value={searchPolicyDetails.policyNo}
                  onChange={handleInputChange}
                  icon="fa fa-user"
                />
                <InputGroupWithIcon
                  Label="Net Amt."
                  ColSize={{ md: 4, sm: 4, lg: 2 }}
                  type="text"
                  name="netAmount"
                  value={searchPolicyDetails.netAmount}
                  onChange={handleInputChange}
                  icon="fa fa-money" // Adjust the icon as needed
                />
                <InputGroupWithIcon
                  Label="Company"
                  ColSize={{ md: 4, sm: 4, lg: 2 }}
                  type="text"
                  name="companyName"
                  value={searchPolicyDetails.companyName}
                  onChange={handleInputChange}
                  icon="fa fa-building" // Adjust the icon as needed
                />
                <InputGroupWithIcon
                  Label="External Re."
                  ColSize={{ md: 4, sm: 4, lg: 2 }}
                  type="text"
                  name="externalRemarks"
                  value={searchPolicyDetails.externalRemarks}
                  onChange={handleInputChange}
                  icon="fa fa-sticky-note" // Adjust the icon as needed
                />
                <InputGroupWithIcon
                  Label="Insured Name"
                  ColSize={{ md: 4, sm: 4, lg: 2 }}
                  type="text"
                  name="policyHolderName"
                  value={searchPolicyDetails.policyHolderName}
                  onChange={handleInputChange}
                  icon="fa fa-sticky-note" // Adjust the icon as needed
                />
                <Col
                  md={12}
                  sm={12}
                  lg={1}
                  className="d-flex justify-between items-center"
                >
                  <Button
                    onClick={handleSearchPolicy}
                    style={{
                      backgroundColor: "#20336b",
                      borderRadius: "8px",
                      color: "white",
                      padding: "5px 10px",
                      fontSize: "20px",
                    }}
                  >
                    <i class="fa fa-search"></i>
                  </Button>
                  <Button
                    onClick={resetFilter}
                    style={{
                      backgroundColor: "red",
                      borderRadius: "8px",
                      color: "white",
                      padding: "5px 10px",
                      fontSize: "20px",
                    }}
                  >
                    <i class="	fa fa-refresh"></i>
                  </Button>
                </Col>
              </Row>
            ) : (
              <Dropdown
                show={showDropdown}
                onClose={() => setShowDropdown(false)}
              >
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="w-100"
                  style={{
                    backgroundColor: "#20336b",
                    color: "white",
                    borderColor: "#20336b",
                  }}
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  Policy Filter Options
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  <Dropdown.Item as="div" onClick={(e) => e.stopPropagation()}>
                    <Form.Select
                      aria-label="Default select example"
                      value={selectedValue}
                      onChange={handleSearchPolicyByActiveAndEx }
                    >
                      <option value="All">
                        All {CountsPolicyAsType.TotalPolicy}
                      </option>
                      <option value="Active">
                        Active {CountsPolicyAsType.TotalActivePolicy}
                      </option>
                      <option value="Expired">
                        Expired {CountsPolicyAsType.TotalExpiredPolicy}
                      </option>
                      <option value="30DayExpired">
                      30DayExpired 
                      </option>
                    </Form.Select>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" onClick={(e) => e.stopPropagation()}>
                    <InputGroup>
                      <InputGroup.Text
                        id="basic-addon1"
                        onClick={refreshSearchItem}
                        className="cursor-pointer"
                      >
                        PolicyNo
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="policyNo"
                        value={searchPolicyDetails.policyNo}
                        onChange={handleInputChange}
                        // onKeyDown={handleSearchPolicy}
                      />
                    </InputGroup>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" onClick={(e) => e.stopPropagation()}>
                    <InputGroup>
                      <InputGroup.Text
                        id="basic-addon1"
                        onClick={refreshSearchItem}
                        className="cursor-pointer"
                      >
                        Net Amt.
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="netAmount"
                        value={searchPolicyDetails.netAmount}
                        onChange={handleInputChange}
                        // onKeyDown={handleSearchPolicy}
                      />
                    </InputGroup>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" onClick={(e) => e.stopPropagation()}>
                    <InputGroup>
                      <InputGroup.Text
                        id="basic-addon1"
                        onClick={refreshSearchItem}
                        className="cursor-pointer"
                      >
                        Company
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="companyName"
                        value={searchPolicyDetails.companyName}
                        onChange={handleInputChange}
                        // onKeyDown={handleSearchPolicy}
                      />
                    </InputGroup>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" onClick={(e) => e.stopPropagation()}>
                    <InputGroup>
                      <InputGroup.Text
                        id="basic-addon1"
                        onClick={refreshSearchItem}
                        className="cursor-pointer"
                      >
                        External Re.
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="externalRemarks"
                        value={searchPolicyDetails.externalRemarks}
                        onChange={handleInputChange}
                        // onKeyDown={handleSearchPolicy}
                      />
                    </InputGroup>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" onClick={(e) => e.stopPropagation()}>
                    <InputGroup>
                      <InputGroup.Text
                        id="basic-addon1"
                        onClick={refreshSearchItem}
                        className="cursor-pointer"
                      >
                        Insured Name
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="policyHolderName"
                        value={searchPolicyDetails.policyHolderName}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="div"
                    onClick={(e) => e.stopPropagation()}
                    className="flex justify-between"
                  >
                    <Button
                      onClick={handleSearchPolicy}
                      style={{
                        backgroundColor: "#20336b",
                        borderRadius: "8px",
                        color: "white",
                        padding: "5px 10px",
                        fontSize: "20px",
                      }}
                    >
                      <i class="fa fa-search"></i>
                    </Button>
                    <Button
                      onClick={resetFilter}
                      style={{
                        backgroundColor: "red",
                        borderRadius: "8px",
                        color: "white",
                        padding: "5px 10px",
                        fontSize: "20px",
                      }}
                    >
                      <i class="	fa fa-refresh"></i>
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Card.Header>
          <Card.Body className="scrollable-card-body p-0 m-0">
            <Row className="mx-0 ">
              {policyData.length > 0 ? (
                policyData.map((item) => (
                  <Col md={6} sm={12} lg={4}>
                    <Card
                      style={{
                        border: item.isPolicyExpired
                          ? "2px solid #e63b3b"
                          : "2px solid #6366f1",
                      }}
                      className="projcard p-0"
                    >
                      <Card.Header
                        style={{
                          backgroundColor: item.isPolicyExpired
                            ? "#e63b3b"
                            : "#20336b",
                          color: "white",
                          fontSize: "16px",
                          padding: "8px",
                          textAlign: window.innerWidth < 768 && "center",
                        }}
                      >
                        <div>
                          <span>Policy No: {item.policyNo}</span>
                        </div>
                        <div>
                          {item.previousPolicyNo && (
                            <span>Prev.PolicyNo: {item.previousPolicyNo}</span>
                          )}
                        </div>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <Table bordered className="m-0">
                          <tbody>
                            <tr>
                              <td>
                                <strong>Insured Name</strong>
                              </td>
                              <td>{item.policyHolderName}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Policy Type</strong>
                              </td>
                              <td>{item.policyTypeName}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Insurance Company</strong>
                              </td>
                              <td>{item.insuranceCompanyName}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>From-To</strong>
                              </td>
                              <td>
                                {convertDateFormatWithOutTime(
                                  item.policyStartDate
                                )}{" "}
                                <strong>To</strong>{" "}
                                {convertDateFormatWithOutTime(
                                  item.policyEndDate
                                )}
                              </td>
                            </tr>
                            {/* <tr>
                              <td>
                                <strong>Premium Amt.</strong>
                              </td>
                              <td>₹{item.premiumAmt}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>GST</strong>
                              </td>
                              <td>
                                {item.serviceTax} (₹{item.serviceTaxAmount})
                              </td>
                            </tr> */}
                            <tr>
                              <td>
                                <strong>Net Premium</strong>
                              </td>
                              <td>
                                ₹{item.premiumAmt} + ₹{item.serviceTaxAmount} (
                                {item.serviceTax}%) =₹{item.netAmount}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Remark</strong>
                              </td>
                              <td>{item.externalRemarks}</td>
                            </tr>
                          </tbody>
                        </Table>
                        {item.imagePath && <div className="d-flex justify-between px-2">
                          <NavLink
                            to={
                              item.imagePath &&
                              UserSetting.imgURL + item.imagePath
                            }
                            target="_blank"
                            onClick={() => CountViewPolicy(item.policyID)}
                            className=" align-middle flex justify-center  py-2 text-blue-700 underline font-bold"
                          >
                            <ion-icon
                              name="document-text-outline"
                              style={{ color: "red", fontSize: "22px" }}
                            ></ion-icon>{" "}
                            View Policy
                          </NavLink>
                          <button
                            onClick={() => SendSMSAndEmail(item.policyID)}
                            className=" align-middle flex justify-center py-2  text-green-600 underline font-bold"
                          >
                            <ion-icon
                              name="logo-whatsapp"
                              style={{ fontSize: "22px" }}
                            ></ion-icon>
                            Send Policy
                          </button>
                        </div>}
                      </Card.Body>
                      <Card.Footer
                        className="d-flex justify-content-between w-full m-0"
                        style={{
                          borderTop: item.isPolicyExpired
                            ? "2px solid #e63b3b"
                            : "2px solid #20336b",
                          padding: window.innerWidth < 350 ? "10px 5px" : "",
                        }}
                      >
                        <NavLink
                          // to="/Courier"
                          onClick={() => {
                            setCurrentSelectPolicyID(item.policyID);
                            setShowCourierModel(true);
                          }}
                          className="btn-tapti d-flex align-items-center"
                        >
                          Courier
                        </NavLink>

                        <NavLink
                          // to="/Endorsement"
                          onClick={() => {
                            setCurrentSelectPolicyID(item.policyID);
                            setShowEndorsementModel(true);
                          }}
                          className="btn-tapti d-flex align-items-center"
                        >
                          Endorsement
                        </NavLink>

                        <NavLink
                          // to="/Claim"
                          onClick={() =>{
                            setCurrentSelectPolicyID(item.policyID)
                            setShowClaimModel(true);
                          }
                          }
                          className="btn-tapti d-flex align-items-center"
                        >
                          Claim
                        </NavLink>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))
              ) : (
                <p className="text-center mt-10 text-2xl text-red-500 font-black">
                  Not Any Policy Found!
                </p>
              )}
            </Row>
          </Card.Body>
        </Card>
      </>

      <Modal
        show={showEndorsementModel}
        onHide={() => setShowEndorsementModel(false)}
        dialogClassName="modal-dialog-scrollable"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <span className="text-center mx-auto">Endorsement In Policy</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Endorsement />
        </Modal.Body>
      </Modal>

      <Modal
        show={showClaimModel}
        onHide={() => setShowClaimModel(false)}
        dialogClassName="modal-dialog-scrollable"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <span className="text-center mx-auto">Claim Policy</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Claim />
        </Modal.Body>
      </Modal>

      <Modal
        show={showCourierModel}
        onHide={() => setShowCourierModel(false)}
        dialogClassName="modal-dialog-scrollable"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <span className="text-center mx-auto">Courier of Policy</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Courier />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Dashboard;
