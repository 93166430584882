import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import App from "./App";
import Cookies from "js-cookie";
import setAuthorizationToken from "./Utils/setAuthorizationToken";
import { LoadingProvider } from "../src/Loading/LoadingContext";
import { CurrentPolicyProvider } from "./actions/CurrentPolicyContext";
import { decryptAndGetItemLocalStorage  } from "./Utils/cryptosessionStorage";

// let Token = Cookies.get("token");
let Token = decryptAndGetItemLocalStorage ('token')
// console.log(Token);
setAuthorizationToken(Token);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <LoadingProvider>
      <CurrentPolicyProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CurrentPolicyProvider>
    </LoadingProvider>
  </Provider>
);
